/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 70vh;
    background-color: rgba(59, 59, 59, 0.8);
    overflow: hidden;
    padding: 0;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }
  
  #hero .carousel-item {
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  #hero .carousel-item::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 120px;
  }
  
  #hero .carousel-content {
    text-align: center;
  }
  
  #hero h2 {
    color: #2e2e2e;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero h2 span {
    color: #e96b56;
  }
  
  #hero p {
    width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: arken(#545454, 15);
  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-start,
  #hero .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon,
  #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
    color: #545454;
  }
  
  #hero .carousel-indicators li {
    cursor: pointer;
    background: #e96b56;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-bottom: 180px;
    opacity: 0.2;
  }
  
  #hero .carousel-indicators li.active {
    opacity: 1;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid #e96b56;
    background: #e96b56;
  }
  
  #hero .btn-get-started:hover {
    background: transparent;
    color: #e96b56;
    text-decoration: none;
  }
  
  @media (max-height: 768px),
  (max-width: 1200px) {
    #hero {
      margin-bottom: 60px;
    }
  
    #hero .carousel-container {
      padding-bottom: 0;
    }
  
    #hero .carousel-indicators li {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 768px) {
    #hero {
      height: 90vh;
    }
  
    #hero .carousel-item {
      height: 90vh;
    }
  }
  
  @media (min-width: 1024px) {
    #hero p {
      width: 50%;
    }
  
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
      width: 5%;
    }
  }

  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e96b56;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/
.featured {
  position: relative;
  z-index: 2;
}

.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.featured .icon-box i {
  color: #e96b56;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
  line-height: 0;
}

.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}

.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured .icon-box:hover {
  background: #e96b56;
}

.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}

.about .content ul {
  list-style: none;
  padding: 10px;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e96b56;
}

.about .content p:last-child {
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #e96b56;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fbe2dd;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #545454;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #e96b56;
  border-color: #e96b56;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #e96b56;
}

.services .icon-box:hover .icon::before {
  background: #ef9383;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}
